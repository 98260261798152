import React, { useEffect, useState } from 'react';

import '../css/Footer.css';
import Button from '@mui/material/Button';
import useWindowDimensions from './UseWindowDimensions';

export interface IProps {
  // serverConnected: number
}

export default function Footer(props: IProps) {
  const year = new Date().getFullYear();
  const [promptInstall, setPromptInstall] = useState(undefined);

  const { width } = useWindowDimensions();
  let containerStyle: React.CSSProperties = { position: 'relative', textAlign: 'center' };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();

      console.log("we are being triggered :D");

      setPromptInstall(e);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const handleClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();

    if (!promptInstall) {
      return;
    }

    if (promptInstall) {
      (promptInstall as any).prompt();
    }
  };


  // const serverConnected = props.serverConnected > 0 ? <small title='Numero de ligações'>({props.serverConnected})</small> : '';

  return (
null
  );
}