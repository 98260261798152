import React from "react";
import IStream from "../models/Stream";

import IconButton from '@mui/material/IconButton';

import "../css/StreamCard.css";
import StreamCardTitle from "./StreamCardTitle";
import YouTube, { YouTubeProps } from 'react-youtube';
import { useDispatch } from "react-redux";
import { setManualPlay } from "../redux/actions/AppActions";
import { IPremiereVideo } from "../models/PremiereVideo";

interface IProps {
  stream: IStream;
  premiere?: IPremiereVideo;
  autoPlay: boolean;

  onClick: (stream: IStream, url: string) => void;
  onManualPlay: (stream: IStream, value: boolean) => void;
}

export default function StreamCard(props: IProps) {
  const dispatch = useDispatch();
  // const [player, setPlayer] = useState<YouTubePlayer>();
  const { channelId, name, isOnline, manualPlay } = props.stream;
  let { liveVideoId, thumbnail } = props.stream;
  // const streamUrl = `https://www.youtube.com/embed/live_stream?channel=${channelId}&rel=0&autoplay=0&mute=1&controls=0`;
  // const streamUrlAutoplay = `https://www.youtube.com/embed/live_stream?channel=${channelId}&rel=0&autoplay=1&mute=1&controls=0`;
  // const [streamUrlState, setStreamUrl] = useState(props.autoPlay || manualPlay ? streamUrlAutoplay : streamUrl);
  let thumbnailStyle: {
    borderBottom: string;
    backgroundImage: string;
    backgroundSize: string;
    backgroundPosition: string;
  } = {
    borderBottom: "4px solid #e6c3c3",
    backgroundImage: "",
    backgroundSize: "",
    backgroundPosition: "",
  };

  if (isOnline) {
    thumbnailStyle.borderBottom = "4px solid #F80004";
    if (!(props.autoPlay || manualPlay)) {
      thumbnailStyle.backgroundImage = `url(${thumbnail})`;
      thumbnailStyle.backgroundSize = "cover";
      thumbnailStyle.backgroundPosition = "center";
    }
  } else {
    if (thumbnail === "") {
      thumbnail = "/emissao_offline.png";
    }
    thumbnailStyle.backgroundImage = `url(${thumbnail})`;
    thumbnailStyle.backgroundSize = "cover";
    thumbnailStyle.backgroundPosition = "center";
  }

  // useEffect(() => {
  //   const newStreamUrl = props.autoPlay ? streamUrlAutoplay : streamUrl;

  //   if (newStreamUrl !== streamUrlState) {
  //     setStreamUrl(newStreamUrl);
  //   }
  // }, [props.autoPlay, streamUrlAutoplay, streamUrl, streamUrlState]);

  // console.log('render', name, isOnline, props.autoPlay);

  // const onReady = (event: { target: YouTubePlayer }) => {
  //   // console.log('Video ready', name, props.autoPlay, event.target);

  //   // setPlayer(event.target);
  // };
  const onPlay = () => {
    console.log("Play " + name);
    dispatch(setManualPlay(channelId, true));

    props.onManualPlay(props.stream, true);
  };
  const onPause = () => {
    console.log("Pause " + name);
    dispatch(setManualPlay(channelId, false));

    props.onManualPlay(props.stream, false);
  };
  const onPlayVideo = () => {
    dispatch(setManualPlay(channelId, true));

    props.onManualPlay(props.stream, true);
  };

  const opts: YouTubeProps['opts'] = {
    playerVars: {
      autoplay: props.autoPlay || (props.autoPlay && manualPlay) ? 1 : 0,
      controls: 1,
      modestbranding: 1,
      disablekb: 1,
    },
  };

  return (
    <div className="channel">
      <div
        className="thumbnail"
        style={thumbnailStyle}
        title={!isOnline ? "Emissão offline" : ""}
      >
        {/* {isOnline && 
          <div className="controlsContainer">
            <div className="controls">
              <button type="button" onClick={onPlayVideo} disabled={!player}>
                Play
              </button>
              <button type="button" onClick={onPauseVideo} disabled={!player}>
                Pause
              </button>
            </div>
          </div>} */}
        {isOnline ? (
          props.autoPlay || manualPlay ? (
            <YouTube
              videoId={liveVideoId}
              opts={opts}
              onPlay={onPlay}
              onPause={onPause}
            />
          ) : (
            <div className="controlsContainer">
              <div className="controls">
                <IconButton
                  className="youtube_play_icon"
                  onClick={onPlayVideo}
                  disableRipple
                >
                  <svg
                    height="22%"
                    version="1.1"
                    viewBox="0 0 68 48"
                    width="22%"
                  >
                    <path
                      className="youtube_play_icon_svg"
                      d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                    ></path>
                    <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                  </svg>
                </IconButton>
              </div>
            </div>
          )
        ) : (
          ""
        )}
      </div>

      <StreamCardTitle stream={props.stream} onClick={props.onClick} />
    </div>
  );
}
