import { SET_PINNED, SET_MANUAL_PLAY, AppActionTypes } from '../types/AppTypes';

export const setPinned = (channelId: string, value: boolean): AppActionTypes => {
  return {
    type: SET_PINNED,
    payload: { channelId, value }
  };
};

export const setManualPlay = (channelId: string, value: boolean): AppActionTypes => {
  return {
    type: SET_MANUAL_PLAY,
    payload: { channelId, value }
  };
};