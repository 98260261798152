import styled, { keyframes } from 'styled-components';

import '../css/Spinner.css';

interface IProps {
  size?: number;
}

export default function Spinner(props: IProps) {
  const size = (props.size || 80);
  const sizeStyle = { width: size, height: size };

  const spin = keyframes`
    0% {
      top: ${(size / 2)}px;
      left: ${(size / 2)}px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: ${(size)}px;
      height: ${(size)}px;
      opacity: 0;
    }
  `;

  const Box = styled.div`animation: ${spin} ${size > 80 ? 1 : 2}s cubic-bezier(0, 0.2, 0.8, 1) infinite`;

  return (
    <div className="spinner">
      <div className="lds-ripple" style={sizeStyle}>
        <Box />
        {size > 80 ? <Box /> : ''}
      </div>
    </div>
  );
}