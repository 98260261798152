import { SET_PINNED, SET_MANUAL_PLAY, AppActionTypes, AppStateType } from "../types/AppTypes";

const initialState: AppStateType = {
  pinned: {},
  manualPlay: {},
};

export const appReducer = (state = initialState, action: AppActionTypes): AppStateType => {
  switch (action.type) {
    case SET_PINNED: {
      const pinned = state.pinned;

      if(action.payload.value) {
        pinned[action.payload.channelId] = action.payload.value;
      } else {
        delete pinned[action.payload.channelId];
      }

      return {
        ...state,
        pinned
      };
    }
    case SET_MANUAL_PLAY: {
      const manualPlay = state.manualPlay;

      if(action.payload.value) {
        manualPlay[action.payload.channelId] = action.payload.value;
      } else {
        delete manualPlay[action.payload.channelId];
      }

      return {
        ...state,
        manualPlay
      };
    }
    default:
      return state;
  }
}
