import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './components/App';
// import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import store from './redux';
import ReactGA from 'react-ga';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';


ReactGA.initialize('G-PK01DF3ZN4', { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);

store.subscribe(() => localStorage.setItem('reduxState', JSON.stringify(store.getState())));
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);


root.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
