import React from 'react';
import IStream from '../models/Stream';
import Spinner from './Spinner';

import '../css/Statistics.css';

export interface IProps {
  streams: Array<IStream>;
}

export default function Statistics(props: IProps) {
  const streams = props.streams;
  const totalOnline = props.streams.filter(s => Number(s.concurrentViewers) > 0).length;
  const totalViewers = streams.reduce((sum, current) => sum + Number(current.concurrentViewers), 0);
  const size = 20;

  return (
    <div className="statistics">
      <div>Canais:&nbsp;
        {streams.length > 0 ?
          <b>{streams.length}</b>
          :
          <Spinner size={size} />
        }
      </div>
      <div>Online:&nbsp;
        {streams.length > 0 ?
          <b>{totalOnline}</b>
          :
          <Spinner size={size} />
        }
      </div>
      <div>Audiência:&nbsp;
        {streams.length > 0 ?
          <b>{totalViewers}</b>
          :
          <Spinner size={size} />
        }
      </div>

    </div>
  );
}
