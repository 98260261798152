import pkg from '../package.json';

let config: { [key: string]: { endpoint: string } } = {
  development: {
    endpoint: pkg['proxy.development']
  },
  production: {
    endpoint: pkg['proxy.production'] /*window.location.hostname*/
  }
}

export default config;