import IconButton from '@mui/material/IconButton';
import YoutubeIcon from '@mui/icons-material/YouTube';
import IStream from "../models/Stream";

interface IProps {
  stream: IStream;

  onClick: (stream: IStream, url: string) => void;
}

export default function StreamCardTitle(props: IProps) {
  const { name, title, channelId, concurrentViewers, isOnline } = props.stream;
  const streamUrl = `https://www.youtube.com/embed/live_stream?channel=${channelId}&rel=0&autoplay=1&mute=0&controls=1`;
  let divTitle = isOnline ? "Clica para visualizar no topo" : "Emissão offline";

  return (
    <div
      className="channel_info"
      title={divTitle}
      onClick={() => props.onClick(props.stream, streamUrl)}
    >
      <div className='channel_title'>
        <h4>{name}</h4>
        {isOnline ? '' : (
          <IconButton
            onClick={() => { window.open(`https://youtube.com/channel/${props.stream.channelId}`, '_blank') }}
            disableRipple
            size='small'
            color='secondary'
          >
            <YoutubeIcon color='primary'></YoutubeIcon>
          </IconButton>
        )}
      </div>

      <h5>{isOnline ? title : ""}</h5>


      {isOnline && <span className="youtube_info">{concurrentViewers}</span>}
    </div>
  );
}
