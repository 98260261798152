import { IPremiereVideo } from "../models/PremiereVideo";
import IStream from "../models/Stream";
// import Info from '@material-ui/icons/Info';
// import Tooltip from '@material-ui/core/Tooltip';

interface IProps {
  stream: IStream;
  premiere: IPremiereVideo | undefined;

  onClick: (stream: IStream, url: string) => void;
}

export default function StreamCardPremiereTitle(props: IProps) {
  const { name } = props.stream;
  let title = "";
  let startTime = "";
  let divTitle = "";

  if (props.premiere) {
    title = props.premiere?.title;
    const date = new Date(props.premiere?.startTime);
    startTime = date.toLocaleDateString();
    divTitle = `Estreia a ${date.toLocaleString()}`;
  }

  return (
    <div className="channel_info" title={divTitle}>
      <h4>{name}</h4>
      <h5>{title}</h5>
      <span className="premiere_info">{startTime}</span>
    </div>
  );
}
