import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";
import config from "../config";
import io from "socket.io-client";
import mongoose from "mongoose";

import "../css/App.css";
import StreamCard from "./StreamCard";
import IStream from "../models/Stream";
import FooterContainer from "./FooterContainer";
import Toolbar from "./Toolbar";
import { FilterType, OrderType } from "../models/Toolbar";
import CircularProgress from '@mui/material/CircularProgress';

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux";
import {
  setAutoPlay,
  setFilterBy,
  setOrderBy,
  setVideoColumns,
} from "../redux/actions/ToolbarActions";
import StreamCardPremiere from "./StreamCardPremiere";

interface IProps {}

interface IState {
  streams: Array<IStream>;
  filteredStreams: Array<IStream>;
  selectedStream: IStream;
}

const environment = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;

const socket = io(config[environment].endpoint);
console.log("endpoint", environment, config[environment].endpoint);
let hasConnected = false;
const iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();

export default function App(props: IProps) {
  // const [ serverConnected, setServerConnected ] = useState(0);
  const [state, setState] = useState({
    streams: [],
    filteredStreams: [],
    order: "Nome",
    filter: "",
    autoPlay: false,
    selectedStream: {
      channelId: "",
      concurrentViewers: 0,
      _id: new mongoose.Types.ObjectId(),
      name: "",
      title: "",
      avatar: "",
      thumbnail: "",
      group: 0,
      isOnline: false,
      liveVideoId: "",
      premiereVideos: [],
      pinned: false,
      manualPlay: false,
    },
  } as IState);
  // Redux state
  const { pinned, manualPlay } = useSelector((state: AppState) => state.app);
  const { orderBy, filterBy, videoColumns, autoPlay } = useSelector(
    (state: AppState) => state.toolbar
  );
  const dispatch = useDispatch();

  const handlerInit = useCallback(
    (streams: IStream[]) => {
      console.log("init", streams.length);

      if (!hasConnected) {
        const novoTempo = streams.find(
          (s) => s.channelId === "UCm0_NzofPCXJ44djiyz_uBg"
        );

        streams.map((s) => {
          s.pinned = pinned[s.channelId] || false;
          s.manualPlay = manualPlay[s.channelId] || false;

          return s;
        });

        if (novoTempo) {
          setState((state) => ({
            ...state,
            streams: [...streams],
            selectedStream: novoTempo,
            filteredStreams: getOrderStream(
              orderBy,
              getFilterStream(filterBy, [...streams])
            ),
          }));

          hasConnected = true;
        }
      }
    },
    [filterBy, orderBy, pinned, manualPlay]
  );

  const handlerNewStream = useCallback(
    (stream: IStream) => {
      console.log("newStream", stream.name, stream.channelId);

      stream.pinned = false;

      setState((state) => ({
        ...state,
        streams: [...state.streams, stream],
        filteredStreams: getOrderStream(
          orderBy,
          getFilterStream(filterBy, [...state.streams, stream])
        ),
      }));
    },
    [filterBy, orderBy]
  );

  const handlerChangeStream = useCallback(
    (stream: IStream) => {
      console.log("changeStream", stream);

      setState((state) => {
        const list = state.streams.map((item, j) => {
          if (item.channelId === stream.channelId) {
            return {
              ...item,
              ...stream,
            };
          } else {
            return item;
          }
        });

        return {
          ...state,
          streams: list,
          filteredStreams: getOrderStream(orderBy, list),
        };
      });
    },
    [orderBy]
  );

  const handlerPushStreams = useCallback(
    async (streams: IStream[]) => {
      console.log("pushStreams", streams.length);

      const currentStreams = state.streams;

      await streams.map((stream) => {
        let currentStream = currentStreams.filter(
          (s) => s.channelId === stream.channelId
        );

        if (currentStream && currentStream.length === 0) {
          currentStream[0] = stream;
        }

        return undefined;
      });

      setState((state) => ({
        ...state,
        streams: [...currentStreams],
        filteredStreams: getOrderStream(
          orderBy,
          getFilterStream(filterBy, [...currentStreams])
        ),
      }));
    },
    [filterBy, orderBy, state.streams]
  );

  const handlerDeleteStream = useCallback(
    (streamId: string) => {
      console.log("deleteStream", streamId);

      const obj = new mongoose.Types.ObjectId(streamId)._id;

      if (obj) {
        const streams = state.streams.filter((s) => !obj.equals(s._id));

        console.log("deleteStream deleted");

        setState((state) => ({
          ...state,
          streams: [...streams],
          filteredStreams: getOrderStream(
            orderBy,
            getFilterStream(filterBy, [...streams])
          ),
        }));
      }
    },
    [filterBy, orderBy, state.streams]
  );

  useEffect(() => {
    socket.on("init", handlerInit);
    socket.on("newStream", handlerNewStream);
    socket.on("changeStream", handlerChangeStream);
    socket.on("pushStreams", handlerPushStreams);
    socket.on("deleteStream", handlerDeleteStream);
    // socket.on('connectedEvent', (connected: number) => setServerConnected(connected));

    return () => {
      socket.off("init");
      socket.off("newStream");
      socket.off("changeStream");
      socket.off("pushStreams");
      socket.off("deleteStream");
      // socket.off('connectedEvent');
    };
  }, [
    handlerInit,
    handlerNewStream,
    handlerChangeStream,
    handlerPushStreams,
    handlerDeleteStream,
  ]);

  const getStreamUrl = (stream: IStream) => {
    return `https://www.youtube.com/embed/live_stream?channel=${stream.channelId}&rel=0&autoplay=1&mute=0&controls=1`;
  };

  const handlerStreamClick = (stream: IStream, url: string) => {
    if (Number(stream.concurrentViewers) > 0) {
      console.log("handlerStreamClick", url);

      if (iframeRef && iframeRef.current) {
        iframeRef.current.scrollIntoView({ behavior: "smooth" });
      }

      setState((state) => ({
        ...state,
        selectedStream: stream,
      }));
    } else {
      console.log("handlerStreamClick discarded", url);
    }
  };

  const changeOrder = (value: OrderType): void => {
    console.log("changeOrder", value);

    dispatch(setOrderBy(value));

    setState((state) => ({
      ...state,
      filteredStreams: getOrderStream(value, state.streams),
    }));
  };

  const changeFilter = (value: FilterType): void => {
    console.log("changeFilter", value);

    dispatch(setFilterBy(value));

    setState((state) => ({
      ...state,
      filteredStreams: getFilterStream(value, state.streams),
    }));
  };

  const changeVideosPorColuna = (value: number): void => {
    console.log("changeVideosPorColuna", value);

    dispatch(setVideoColumns(value));
  };

  const changeAutoPlay = (value: boolean): void => {
    console.log("changeAutoPlay", value);

    ReactGA.event({
      category: "Toolbar",
      action: "AutoPlay",
      label: value.toString(),
    });

    dispatch(setAutoPlay(value));
  };

  const handlerManualPlay = (stream: IStream, value: boolean): void => {
    console.log("handlerManualPlay", stream.channelId, value);
    const index = state.streams.indexOf(stream);
    const streams = state.streams;

    streams[index].manualPlay = value;

    setState((state) => ({
      ...state,
      filteredStreams: streams,
    }));
  };

  const getOrderStream = (order: OrderType, streams: IStream[]): IStream[] => {
    switch (order) {
      case "Número de visualizações":
        return streams.sort((a, b) => {
          var aViewers = Number(a.concurrentViewers);
          var bViewers = Number(b.concurrentViewers);
          var aName = a.name;
          var bName = b.name;

          if (aViewers === bViewers) {
            return aName < bName ? -1 : aName > bName ? 1 : 0;
          } else {
            return aViewers > bViewers ? -1 : 1;
          }
        });

      default:
        return streams.sort((a, b) => {
          var aGroup = a.group;
          var bGroup = b.group;
          var aName = a.name;
          var bName = b.name;

          if (aGroup === bGroup) {
            return aName < bName ? -1 : aName > bName ? 1 : 0;
          } else {
            return aGroup > bGroup ? -1 : 1;
          }
        });
    }
  };

  const getFilterStream = (
    filter: FilterType,
    streams: IStream[]
  ): IStream[] => {
    switch (filter) {
      case "Emissões Online":
        return streams.filter((s) => s.concurrentViewers);

      default:
        return streams;
    }
  };

  const getGridTemplateColumns = () => {
    let gridTemplateColumns = "";

    for (let i = 1; i <= videoColumns; i++) {
      gridTemplateColumns += "1fr ";
    }

    return gridTemplateColumns;
  };

  const { filteredStreams, selectedStream } = state;
  const streamUrl = getStreamUrl(selectedStream);
  let thumbnailStyle: {
    backgroundImage: string;
    backgroundSize: string;
    backgroundPosition: string;
  } = { backgroundImage: "", backgroundSize: "", backgroundPosition: "" };
  const galleryStyle = { gridTemplateColumns: getGridTemplateColumns() };

  if (!selectedStream.isOnline) {
    let thumbnail = "/emissao_offline.png";

    if (selectedStream.thumbnail !== "") {
      thumbnail = selectedStream.thumbnail;
    }

    thumbnailStyle.backgroundImage = `url(${thumbnail})`;
    thumbnailStyle.backgroundSize = "cover";
    thumbnailStyle.backgroundPosition = "center";
  }

  console.log("render app");

  return (
    <div>
      <div className="container">
        <section className="intro" style={thumbnailStyle}>
          {selectedStream.isOnline && (
            <iframe
              ref={iframeRef}
              id="heroembed"
              title="Youtube"
              src={streamUrl}
              name="player"
              allow="autoplay; encrypted-media"
            ></iframe>
          )}
        </section>

        {/* {selectedStream.isOnline &&
          <div className="channel_info" style={{color: 'white'}}>
            <h4>{selectedStream.isOnline ? selectedStream.title : ''}</h4>
            {selectedStream.isOnline && <span className="youtube_info">{selectedStream.concurrentViewers} ligações</span>}
          </div>
        } */}

        <Toolbar
          changeFilter={(value) => changeFilter(value)}
          changeOrder={(value) => changeOrder(value)}
          changeVideosPorColuna={(value) => changeVideosPorColuna(value)}
          changeAutoPlay={(value) => changeAutoPlay(value)}
        />

        {state.streams.length > 0 ? (
          <div id="target" className="gallery" style={galleryStyle}>
            {filteredStreams.map((stream) => {
              return (
                <>
                  {filterBy !== "Estreias" && (
                    <StreamCard
                      autoPlay={autoPlay}
                      key={stream.channelId}
                      onManualPlay={handlerManualPlay}
                      onClick={handlerStreamClick}
                      stream={stream}
                    />
                  )}
                  {stream.premiereVideos.map((premiere) => (
                    <StreamCardPremiere
                      autoPlay={autoPlay}
                      key={stream.channelId}
                      onManualPlay={handlerManualPlay}
                      onClick={handlerStreamClick}
                      stream={stream}
                      premiere={premiere}
                    />
                  ))}
                </>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              margin: "50px 0 50px 0",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>

      <FooterContainer streams={state.streams} />
    </div>
  );
}
