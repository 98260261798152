import { FilterType, OrderType } from '../../models/Toolbar';
import { SET_ORDER_BY, SET_FILTER_BY, SET_VIDEO_COLUMNS, SET_AUTO_PLAY, ToolbarActionTypes } from '../types/ToolbarTypes';

export const setOrderBy = (field: OrderType): ToolbarActionTypes => {
  return {
    type: SET_ORDER_BY,
    payload: field
  };
};

export const setFilterBy = (field: FilterType): ToolbarActionTypes => {
  return {
    type: SET_FILTER_BY,
    payload: field
  };
};

export const setVideoColumns = (columns: number): ToolbarActionTypes => {
  return {
    type: SET_VIDEO_COLUMNS,
    payload: columns
  };
};

export const setAutoPlay = (value: boolean): ToolbarActionTypes => {
  return {
    type: SET_AUTO_PLAY,
    payload: value
  };
};