import { SET_AUTO_PLAY, SET_FILTER_BY, SET_ORDER_BY, SET_VIDEO_COLUMNS, ToolbarActionTypes, ToolbarStateType } from "../types/ToolbarTypes";

const getDefaultVideoColumns = () => {
  const { innerWidth } = window;
  let videosPorColuna = 6;
  if (innerWidth < 500) {
    videosPorColuna = 1;
  } else if (innerWidth < 700) {
    videosPorColuna = 2;
  } else if (innerWidth < 800) {
    videosPorColuna = 3;
  } else if (innerWidth < 1000) {
    videosPorColuna = 4;
  } else if (innerWidth < 1200) {
    videosPorColuna = 5;
  }

  return videosPorColuna;
}

const initialState: ToolbarStateType = {
  orderBy: 'Nome',
  filterBy: '',
  videoColumns: getDefaultVideoColumns(),
  autoPlay: false
};

export const toolbarReducer = (state = initialState, action: ToolbarActionTypes): ToolbarStateType => {
  switch (action.type) {
    case SET_ORDER_BY: {
      return {
        ...state,
        orderBy: action.payload
      };
    }
    case SET_FILTER_BY: {
      return {
        ...state,
        filterBy: action.payload
      };
    }
    case SET_VIDEO_COLUMNS: {
      return {
        ...state,
        videoColumns: action.payload
      };
    }
    case SET_AUTO_PLAY: {
      return {
        ...state,
        autoPlay: action.payload
      };
    }
    default:
      return state;
  }
}
