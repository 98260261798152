import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const reduxState = localStorage.getItem('reduxState');
const initialState = (reduxState !== null ? JSON.parse(reduxState) : {});

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware())
);

export type AppState = ReturnType<typeof rootReducer>;
export default store;