import React, { useState } from "react";
import { FilterType, OrderType } from "../models/Toolbar";

import "../css/Toolbar.css";
import useWindowDimensions from "./UseWindowDimensions";
import { FormControlLabel, Switch } from '@mui/material';

import { useSelector } from "react-redux";
import { AppState } from "../redux";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { setAutoPlay, setFilterBy, setOrderBy } from "../redux/actions/ToolbarActions";

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export interface IProps {
  changeOrder: (value: OrderType) => void;
  changeFilter: (value: FilterType) => void;
  changeVideosPorColuna: (value: number) => void;
  changeAutoPlay: (value: boolean) => void;
}

const getMaxColumns = (
  height: number,
  width: number,
  videosPorColuna: number,
  changeVideosPorColuna: (value: number) => void
) => {
  let maxColumn = 10;

  if (width < 500) {
    maxColumn = 2;
  } else if (width < 700) {
    maxColumn = 3;
  } else if (width < 800) {
    maxColumn = 4;
  } else if (width < 900) {
    maxColumn = 5;
  } else if (width < 1000) {
    maxColumn = 6;
  } else if (width < 1100) {
    maxColumn = 7;
  } else if (width < 1200) {
    maxColumn = 8;
  } else if (width < 1300) {
    maxColumn = 9;
  }

  if (videosPorColuna > maxColumn) {
    changeVideosPorColuna(maxColumn);
  }

  return maxColumn;
};

export default function Toolbar(props: IProps) {
  const { height, width } = useWindowDimensions();
  const { orderBy, filterBy, videoColumns, autoPlay } = useSelector(
    (state: AppState) => state.toolbar
  );
  const options = [];

  for (
    let i = 1;
    i <=
    getMaxColumns(height, width, videoColumns, props.changeVideosPorColuna);
    i++
  ) {
    options.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  const handleOrderChange = (
    event: React.MouseEvent<HTMLElement>,
    newOrder: OrderType,
  ) => {
    setOrderBy(newOrder)
    props.changeOrder(newOrder)
  };

  const handlerFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: FilterType,
  ) => {
    setFilterBy(newFilter)
    props.changeFilter(newFilter)
  };

  const handleAutoplayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoPlay(event.target.checked);
    props.changeAutoPlay(event.target.checked)
  };

  const handlePerLineChange = (event: { target: { value: any; }; }) => {
    props.changeVideosPorColuna(Number(event.target.value))
  };

  return (
    <div>
      <div className="toolbar">
        <ToggleButtonGroup onChange={handleOrderChange} value={orderBy} color="primary" exclusive sx={{ bgcolor: 'white' }}>
          <ToggleButton value={"Nome" as OrderType}>{"Nome" as OrderType}</ToggleButton>
          <ToggleButton value={"Número de visualizações" as OrderType}>visualizações</ToggleButton>
        </ToggleButtonGroup>

        <div className="options">
          <FormControlLabel label='Auto play' control={<Switch checked={autoPlay} onChange={handleAutoplayChange} />} />
          <FormControl variant="filled" fullWidth color="primary" sx={{ bgcolor: 'white', width: 150 }}>
            <InputLabel id="demo-simple-select-label">Por linha</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={videoColumns} label="Por linha" onChange={handlePerLineChange}>
              {options}
            </Select>
          </FormControl>
          <ToggleButtonGroup onChange={handlerFilterChange} value={filterBy} color="primary" exclusive sx={{ bgcolor: 'white' }}>
            <ToggleButton value="">Tudo</ToggleButton>
            <ToggleButton value={"Emissões Online" as FilterType}>Online</ToggleButton>
            <ToggleButton value={"Estreias" as FilterType}>Estreias</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
}
