import React, { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import Footer from './Footer';
import Statistics from './Statistics';
import { IProps as IStatisticsProps } from './Statistics';
import { IProps as IFooterProps } from './Footer';

interface IProps {
}

type IAllProps = IProps & IStatisticsProps & IFooterProps;

export default function FooterContainer(props: IAllProps) {
  const [sticky, setSticky] = useState(false);
  const streams = props.streams;

  const urlParams = new URLSearchParams(window.location.search);
  const appMode = urlParams.get('app');
  let showFooter = true;

  if (appMode && appMode === '1') {
    showFooter = false;
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== sticky) setSticky(isShow)
    },
    [sticky]
  )

  return (
    <div style={{
        position: "sticky",
        transform: sticky ? "translateY(100%)" : "translateY(0)",
        transition: "transform 400ms ease-in",
        bottom: 0,
        left: 0,
        marginTop: '50px'
      }}>
      <Statistics streams={streams} />

      {/* {showFooter && <Footer serverConnected={props.serverConnected} />} */}
      {showFooter && <Footer />}
    </div>
  );
}