export const SET_PINNED = 'SET_PINNED';
export const SET_MANUAL_PLAY = 'SET_MANUAL_PLAY';

export interface AppStateType {
  pinned: {[key: string]: boolean},
  manualPlay: {[key: string]: boolean}
}

export interface AppPayloadType {
  channelId: string;
  value: boolean;
}

interface SetPinnedByActionType {
  type: typeof SET_PINNED;
  payload: AppPayloadType;
}

interface SetManualPlayByActionType {
  type: typeof SET_MANUAL_PLAY;
  payload: AppPayloadType;
}

export type AppActionTypes = SetPinnedByActionType | SetManualPlayByActionType;