import { FilterType, OrderType } from "../../models/Toolbar";

export const SET_ORDER_BY = 'SET_ORDER_BY';
export const SET_FILTER_BY = 'SET_FILTER_BY';
export const SET_VIDEO_COLUMNS = 'SET_VIDEO_COLUMNS';
export const SET_AUTO_PLAY = 'SET_AUTO_PLAY';

export interface ToolbarStateType {
  orderBy: OrderType,
  filterBy: FilterType,
  videoColumns: number,
  autoPlay: boolean
}

interface SetOrderByActionType {
  type: typeof SET_ORDER_BY;
  payload: OrderType;
}

interface SetFilterByActionType {
  type: typeof SET_FILTER_BY;
  payload: FilterType;
}

interface SetVideoColumnsActionType {
  type: typeof SET_VIDEO_COLUMNS;
  payload: number;
}

interface SetAutoPlayActionType {
  type: typeof SET_AUTO_PLAY;
  payload: boolean;
}

export type ToolbarActionTypes = SetOrderByActionType | SetFilterByActionType | SetVideoColumnsActionType | SetAutoPlayActionType;